import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./signature.css";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { FaFileSignature } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

export function EmailTemplate({
  isModalOpen,
  setModalOpen,
  sigPad,
  userid,
  loeid,
  setSinghLink,
  setPdfViewerUpdate,
}) {
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const clearSignature = () => {
    sigPad.current.clear();
  };

  const saveSignature = async () => {
    const dataURL = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    const blob = await fetch(dataURL).then((res) => res.blob());

    const formData = new FormData();
    formData.append("sign", blob, "signature.png");

    try {
      const response = await fetch(`${process.env.REACT_APP_BASEURL}asests/`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setSinghLink(data?.sign);
        const loeFormData = new FormData();
        loeFormData.append("sign", data?.sign);
        loeFormData.append("loe", loeid);
        const loeSigningResponse = await fetch(
          `${process.env.REACT_APP_BASEURL}loesigning/`,
          {
            method: "POST",
            body: loeFormData,
          }
        );

        if (loeSigningResponse.ok) {
          const loeData = await loeSigningResponse.json();
          console.log("LOE signing successful:", loeData);
          setPdfViewerUpdate(true);
          closeModal(false);
        } else {
          console.error("LOE signing failed:", loeSigningResponse.statusText);
        }
      } else {
        console.error("File upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during file upload or LOE signing:", error);
    }
  };

  return (
    <div className="email-template">
      <IoClose onClick={closeModal} />

      {isModalOpen && (
        <div
          className="signImg"
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              border: "2px solid #000",
              width: "520px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "auto",
              background: "#fff",
              padding: "20px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <IoClose
              onClick={closeModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "24px",
              }}
            />
            <h2>Signature Pad</h2>

            <div className="signaturePlat">
              <SignatureCanvas
                ref={sigPad}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas",
                }}
              />
            </div>
            <div
              style={{
                display: "inline-flex",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <button
                onClick={clearSignature}
                style={{
                  padding: "8px 12px",
                  borderRadius: "6px",
                  background: "#808080",
                  color: "#fff",
                  minWidth: "100px",
                  fontSize: "17px",
                  cursor: "pointer",
                }}
              >
                Clear
              </button>

              <button
                onClick={saveSignature}
                style={{
                  padding: "8px 12px",
                  borderRadius: "6px",
                  background: "#7258DB",
                  color: "#fff",
                  minWidth: "100px",
                  fontSize: "17px",
                  cursor: "pointer",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Signature() {
  const sigPad = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const { userid, loeid } = useParams();
  const [singLink, setSinghLink] = useState(null);

  const [documentData, setDocumentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfViewerUpdate, setPdfViewerUpdate] = useState(false);

  const fetchDocument = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}document/${loeid}/`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setDocumentData(data);
      setPdfViewerUpdate(false);
    } catch (err) {
      setError(err.message);
      setPdfViewerUpdate(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocument();
  }, [pdfViewerUpdate]);

  return documentData?.loeafter_sign ? (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Viewer fileUrl={documentData?.loeafter_sign} />
    </Worker>
  ) : (
    <div className="email-template">
      <div className="container">
        <div className="box">
          <div className="image-center">
            <img src="https://agfinancehub-img.s3.amazonaws.com/aron/assets/Recurso_1play_store.jpg" />
          </div>
          <div className="text">
            <p>
              <b>{documentData?.company_info?.company_name}</b>
            </p>
            <p>
              Company number &nbsp;
              <b>{documentData?.company_info?.no}</b>
            </p>
            <br />
            <p>Registered office address</p>
            <p>{documentData?.company_info?.address1}</p>
            <p>{documentData?.company_info?.address2}</p>
            <p>{documentData?.company_info?.address3}</p>
            <p>{documentData?.company_info?.address4}</p>

            <div className="date">
              <p>{documentData?.company_info?.date}</p>
            </div>
            <p>
              Dear &nbsp;
              {documentData?.company_info?.name},
            </p>
            <br />
            <p>
              <b>Terms of Engagement</b>
            </p>
            <br />
            <p>
              We will be delighted to act for you (the Client) in relation to your
              claim for Research and Development (R&D) Tax Credits (the Services).
              This letter confirms the basis on which AG Finance and Technology
              Group Limited, trading name AG Finance Hub, (Company number 15901932,
              registered office address 71-75 Shelton Street, Covent Garden, London,
              United Kingdom, WC2H 9JQ) will act for you. The terms of this engagement
              will take effect as of the date of this letter, but will also relate to
              any advice given prior to this letter. Headings are included for convenience
              only and shall not affect the construction of this Agreement.
            </p>
            <br />

            <p>
              <b>
                1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Services we will provide to you
              </b>
            </p>
            <br />
            <p>
              We will provide advice and assistance to you in the following areas, where relevant and applicable:
            </p>
            <ul>
              <li>
                {" "}
                <span>(i)</span> identifying eligible R&D expenditure;
              </li>
              <li>
                {" "}
                <span>(ii)</span> liaising with Client personnel to make appropriate enquiries and to request appropriate evidence of eligible R&D expenditure;
              </li>
              <li>
                {" "}
                <span>(iii)</span> preparation of a detailed R&D Report, and/or other documentation as may be necessary, to substantiate your R&D claims;
              </li>
              <li>
                {" "}
                <span>(iv)</span> calculation of your R&D claim(s) in accordance with applicable legislation;
              </li>
              <li>
                {" "}
                <span>(v)</span> filing, or re-filing, your tax returns for the relevant accounting periods and submitting the same to HMRC on your behalf;
              </li>
              <li>
                {" "}
                <span>(vi)</span> addressing any enquiries raised by HMRC, liaising with relevant Client personnel to substantiate any claims and corresponding on your behalf with HMRC to agree any changes which may be required to your claims.
              </li>
            </ul>
            <p>
              In instances whereby you use our services to find you bespoke business finance, you give us consent to share your details with strategic partners, lenders and brokers who may work with us. These details will include all details and documents you provide us as part of your application for finance, in order to provide you with options from various lenders.
            </p>
            <p>
              <b>
                2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Conduct of your claim
              </b>
            </p>
            <br />
            <p>Once we are instructed, Aaron Gera (Ronnie) will be your main point of contact throughout the claims process. Ronnie can be reached by email at <a href="mailto:ronnie@levygera.com">ronnie@levygera.com</a> or <a href="mailto:aaron@agfinancehub.com">aaron@agfinancehub.com</a>. You can also contact our head of taxation, Benedict Hawkins, chartered accountant and chartered tax advisor with 10 years experience in UK corporate tax. You may contact Benedict at <a href="mailto:info@levygera.com">info@levygera.com</a>.</p>
            <p>
              Ronnie may also be assisted by one or more colleagues depending on the nature of your matter and the amount of work involved. We will always strive to ensure that you have consistency of contact with us.
            </p>
            <p>
              Once your matter is under way, we will keep in touch with you as regularly as is necessary to provide a high standard of service, usually by email or telephone as your matter progresses
            </p>
            <br />
            <p>
              We will endeavour to respond to your correspondence promptly at all times. However, because of work commitments, both in and out of the office during the day, your correspondence may be dealt with by another member of the firm, as that will allow your enquiry to be dealt with as promptly as possible.
            </p>
            <p>
              We try to avoid changing the people you are dealing with, but if it should seem appropriate at any stage for the matter to be referred, for all purposes, to another member of the firm, we will explain why that is suggested and arrange for an introduction.
            </p>
            <p>
              To help us keep in touch, please let us know as soon as possible if you change your address, telephone number, or email so that we can keep our records up to date. We would welcome hearing from you with any change of contact details, including when a claim has been completed, as that will ensure we can continue to maintain contact with you in the future.
            </p>
            <br />
            <p>
              <b>
                3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Assistance which we may need from you
              </b>
            </p>
            <br />
            <p>
              You agree that you will provide to us all reasonable assistance, documents, and responses in a timely manner to enable us to provide the Services to you. You understand that if you do not provide us the information we require, we may not be able to file your claim within any given deadline, or at all.
            </p>
            <br />
            <p>
              <b>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our fees</b>
            </p>
            <br />
            <p>
              We have agreed that we will provide the Services to you for a fixed fee, which shall be calculated at
              &nbsp;{documentData?.company_info?.percentage}% of the corporation tax savings which we achieve on your behalf through carrying out the Services (our Fees).
            </p>
            <p>
              Where applicable, VAT will be charged on the amount of our Fees.
            </p>
            <p>
              In the event a tax refund or payable credit is made to you as part of your claim, it is agreed that you will give AG Finance and Technology Group Limited access or visibility to the status of your online CT (Corporate Tax) portal / account on or around the dates which are 14 days and 30 days after the claim has been filed. This is required so that we can assess if the refund or credit relating to your R&D tax claim has been properly reflected.
            </p>
            <p>
              This visibility is also required in order to properly prepare your accounts for their submission to HMRC on your behalf, and to clarify the status of your R&D tax claim on the above-mentioned days.
            </p>
            <p>
              In the event a tax refund or payable credit is made to you as part of your claim, if for any reason you fail to provide the visibility to your CT (corporation tax) account as described above, you agree that AG Finance and Technology Group Limited will be entitled to raise an invoice for its agreed fee, which shall be paid by you within 7 days, on the basis that the R&D tax claim has been approved and (where applicable) paid out, unless you can show AG Finance and Technology Group Limited otherwise through visibility of our CT account.
            </p>
            <p>
              In the event that no tax refund or payable credit is made to you and your corporation tax saving is in the form of a reduction in your future corporation tax bill(s), once your amended tax return is registered on your CT (corporate tax) portal, we shall issue our invoice to you which you agree to pay within 30 days. Again, you will need to provide us with access to your portal or confirmation of registration. Otherwise, we shall be entitled to raise an invoice for our agreed fee, which shall be paid by you within 7 days, on the basis that the R&D tax claim has been registered, unless you can show AG Finance and Technology Group Limited otherwise through visibility of our CT account.
            </p>
            <p>
              In the event of non-payment of our Fees, we reserve the right to charge interest at the statutory rate applicable to the late payment of commercial debts. You also agree to pay any costs or legal fees incurred by us in pursuing an overdue payment.
            </p>
            <br />
            <p>
              <b>
                5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Duration of this agreement
              </b>
            </p>
            <br />
            <p>
              It is agreed that we shall be appointed by you to provide the Services for the accounting periods ending &nbsp;&nbsp;
              {documentData?.company_info?.duration}, (the “Initial Term”). Our fees are chargeable to you for each year that we carry out the Services.
            </p>
            <p>
              In instances where you have not provided us with sufficient information (or any information at all) to allow us to complete the Services for the agreed duration of the agreement, but your company continues to claim R&D tax relief (whether directly or through any other agent), or in instances whereby you inform us you wish to terminate this agreement within the Initial Term and no breach of contract has occurred from our company, your company expressly agrees that our fees shall remain payable for the entire period of the Initial Term. For the avoidance of doubt, this means, you agree that your company will still be responsible for paying us our fees (calculated in line with 4. Our fees above).</p>
            <p>
              You expressly agree, that if required, you will provide sufficient evidence to demonstrate that our agreement is being honoured for the duration of this agreement.</p>
            <p>
              Following the expiry of the Initial Term, this agreement may be terminated by either party, by providing notice in writing or via email within 1 month of the expiry date of the Initial Term. Unless we receive written notice within 1 month of the expiry of the Initial Term, this agreement shall be extended for the following three accounting periods whereby all the relevant provisions of this contract shall apply including section 4 and 5. For the avoidance of doubt, the date of expiry of the Initial Term will be the last day of the month in the last accounting period in this engagement. For example, this means that if the Initial Term expires in December 2024, the date of expiry will be 31st December 2024. You will then have until the end of January 2025 to give notice of termination. Unless we receive notification in writing or email this agreement will be automatically extended until December 2027 with the new Initial terms being accounting periods ending December 2025, December 2026 and December 2027. Should the company amend its accounting period of the month of year end, the Initial term will be adjusted accordingly. </p>
            <br />
            <p>
              <b>
                6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Limitations of advice
              </b>
            </p>
            <br />
            <p>
              You understand that we are providing you with Services relating to the recovery of R&D Tax Credits only. We are not instructed to advise you in relation to any other tax matters, including the effects, if any, of the Services on your tax matters. You understand that you will rely on your own tax advisors for advice on any other tax matters.
            </p>
            <p>
              For the service that we provide you, it is your responsibility to ensure that the information provided to us is accurate, truthful, and complete.
            </p>
            <p>
              In instances where your company requests services for business finance solutions, we are not instructed to advise you in relation to any financial services or business finance solution your company may explore or take under relevant contracts through our services. You agree and accept you will rely on your own financial advisors for advice relating to any business finance solution you explore or agree to be bound by under a relevant contract, in instances whereby these options are presented by us to your company.
            </p>
            <br />
            <p>
              <b>
                7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No guarantee of recovery
              </b>
            </p>
            <br />
            <p>
              You understand that whilst most R&D tax claims are successful, there is no guarantee of recovering any specific amount which we may claim on your behalf, or any amount at all.
            </p>
            <p>
              You understand that we are relying on the information you have provided to us to prepare your claim. Accordingly, we shall not be liable to you should HMRC for any reason decline the whole or any part of a claim which we have submitted on your behalf from the information you have provided to prepare the claim.
            </p>
            <p>
              You are aware that HMRC has the right to make enquiries into a claim and can, in certain circumstances, request a repayment of any tax credit which it has earlier agreed or paid to you. </p>
            <br />
            <p>
              <b>
                8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Documents and confidentiality
              </b>
            </p>
            <br />
            <p>
              We will ensure that all information and documents relating to your company and your claim are kept under strict confidentiality and in accordance with the requirements of the UK Data Protection Act and the UK GDPR regulations.
            </p>
            <p>
              Your company accepts and agrees that we may share your documents and details with our strategic partners, brokers or providers of business finance who work with us, in instances whereby your company requests services for business finance solutions. The aforementioned will share this data with a panel of lenders in order to provide you with any financing options, and where required, terms under relevant contract from those lenders. Your data will be stored, held, and processed as per our and/or their terms of use. By signing our engagement terms your company gives consent for such companies to use the data provided in your finance application for the purpose of bringing you finance options, and, your company consents for AG Finance and Technology Group Limited to provide this information where required in order to bring you financing options as per your requirement. Unless communicated otherwise, via email, our company, strategic partners, brokers or providers of business finance solutions will use your data on the basis that you agree for us to do so. If you require terms of use before the business finance applications are made available, please let us know via email and we will happily do so. It is agreed that the data provided in your application will not be disclosed to any third party for any other purpose than to provide you with business finance options.
            </p>
            <p>
              Your company and ours agree not to disclose any confidential information to any third party, unless (i) we have given each other express written permission on company headed paper; (ii) we are required to do so by law; or (iii) the information is in the public domain and is therefore not considered confidential.
            </p>
            <p>
              The style, structure, and format of our (AG Finance and Technology Group Limited) technical documents supporting the claim, which include our R&D reports, are the proprietary information and intellectual property of our company and therefore the unauthorized exposure or disclosure of such information is forbidden unless required to do so by law. For the avoidance of doubt, this means our proprietary information and/or intellectual property may not be copied, distributed, reproduced, or repurposed in any capacity by any personnel in (or connected to) your company or any third party, unless required to do so by law, or, express permission is provided by our company in writing or by email and done on our company headed paper or company email. In signing this agreement your company acknowledges and agrees that the unauthorized exposure or disclosure of our proprietary information and/or intellectual property may cause significant damage and/or financial loss to AG Finance and Technology Group Limited both directly and indirectly, for which your company will be liable. You also acknowledge and agree that in the event of our technical documents and R&D reports being copied, distributed, reproduced, or repurposed by your company or any third party as a result of your breach of this agreement, in order to gain commercial or financial benefit or competitive advantage, our company will be entitled to the loss of all fees which arise in instances where our engagements are affected, as well as any damages that result from the unauthorized exposure or disclosure of our proprietary information and intellectual property to third parties.
            </p>
            <p>
              Both your company and ours agree to take all reasonable steps and measures to protect and safeguard all proprietary information and/or intellectual property that belongs to the other company, which your company or ours may gain access to or be required to exchange during this engagement.
            </p>
            <br />
            <p>
              <b>
                9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Anti-money laundering compliance
              </b>
            </p>
            <br />
            <p>
              In accordance with our obligations under The Money Laundering, Terrorist Financing and Transfer of Funds Regulations 2017, we are obliged to request evidence of your identity and undertake certain other procedures to establish your credentials. You agree to provide us with all relevant identification documents and other connected information which we may reasonably request.{" "}
            </p>

            <br />
            <p>
              <b>
                10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Regulatory matters
              </b>
            </p>
            <br />
            <p>
              Your contract for the provision of the Services is with AG Finance and Technology Group Limited. Although, AG Finance and Technology Group Limited may use lawyers and legal services to assist in your claim, where and if required. AG Finance and Technology Group Limited is not a law firm and the Solicitors Regulatory Authority does not regulate the Services provided by AG Finance and Technology Group Limited. Though at times our chosen provider of legal assistance is Ray Levy Law Offices, we may choose to use any legal professional we deem suitable. By engaging with AG Finance and Technology Group Limited, you agree that you understand we are not a law firm nor do we have any legal partnership with Ray Levy Law Offices.
            </p>
            <br />
            <p>
              <b>
                11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Confidentiality
              </b>
            </p>
            <br />
            <p>
              For the purposes of this clause, <b>Confidential Information</b> means all confidential information disclosed by you to us in connection with the provision of the Services, including but not limited to information concerning: your business, commercial affairs, customers, clients, suppliers, plans, business operations, processes, product information, know-how, designs, trade secrets, or software. It does not include Confidential Information that: (a) is in the public domain; (b) you have agreed we may disclose; or (c) we are required to disclose by law.
            </p>
            <p>
              We shall keep your Confidential Information confidential and shall not disclose or use it for any purpose other than to provide the Services. On termination of this agreement, we agree to destroy or return to you all documents and materials containing any Confidential Information.
            </p>
            <br />
            <p>
              <b>
                12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Limitation on Liability
              </b>
            </p>
            <br />
            <p>
              It is agreed that, excluding damages that may arise in the event of breach of the provisions in <b>8. Documents and Confidentiality</b>, neither party shall have any liability to the other party, whether in contract, tort, breach of statutory duty, or otherwise, for any loss of profit, or for any indirect or consequential loss arising under or in connection with this agreement.
            </p>
            <p>
              You agree that our total liability to you, whether in contract, tort, breach of statutory duty, or otherwise, arising under or in connection with this agreement shall be no greater than the amount of any Fees received by us in connection with the Services, less the costs our company incurs in connection with delivering those services to your company.
            </p>
            <p>
              We reserve the right to terminate our services and this agreement at any time. In the event that we terminate this agreement, our liability shall remain no greater than the amount of any Fees received by us in connection with the Services, less the costs our company incurs in connection with delivering those services to your company.
            </p>
            <br />
            <p>
              <b>
                13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Law and Jurisdiction
              </b>
            </p>
            <br />
            <p>
              These terms of business are governed by English law, and the parties agree to the exclusive jurisdiction of the English courts in relation to any dispute arising out of them.
            </p>
            <br />
            <p>
              <b>
                14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Communication, Suggestions, and Complaints
              </b>
            </p>
            <br />
            <p>
              We are confident of providing a highly professional service to you. We do, of course, welcome any suggestions you might wish to make which you think could help to improve our service.
            </p>
            <p>
              If, at any time, you are not happy with the service you are receiving from us, please let us know. You are valued by us, and we would like the opportunity to put matters right if any problems do arise. In the first instance, please contact your main point of contact, and we will do what we can to resolve the problem promptly.
            </p>
            <p>
              We would be grateful if you could please sign and return a copy of this letter to us. Do note, however, that these terms will, in any event, automatically become binding once you have instructed us to begin work for you.
            </p>
            <p>
              As this document is important, please keep it in a safe place for future reference. We do hope that this document usefully addresses any immediate queries about the day-to-day handling of the matter and the firm’s terms of business. If you have any queries, please do not hesitate to contact us.
            </p>
            <p>
              We look forward to hearing from you and to being of service to you.
            </p>
            <br />
            <br />
            <p>
              I declare that I have read, understood and agreed to the contents of this letter and that I am authorised to sign this binding agreement on behalf of the company or company’s below.
            </p>
            <br />
            <br />

            <button
              onClick={() => setModalOpen(true)}
              style={{ border: "none", background: "none", padding: 0 }}
            >
              <div
                style={{
                  height: "100px",
                  width: "120px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                  transition: "transform 0.2s ease-in-out",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "scale(1.05)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Click to signature <FaFileSignature className="me-2 fs-4" />
                </span>
              </div>
            </button>

            {isModalOpen && (
              <EmailTemplate
                setModalOpen={setModalOpen}
                isModalOpen={isModalOpen}
                sigPad={sigPad}
                userid={userid}
                loeid={loeid}
                singLink={singLink}
                setSinghLink={setSinghLink}
                setPdfViewerUpdate={setPdfViewerUpdate}
              />
            )}

            <p style={{ marginTop: '8px' }}>Signed by</p>
            <p>
              For and on behalf of
              {documentData?.company_info?.company_name}
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p>
              <b>Signature</b>
            </p>
            <p>
              <b>For and on behalf of AG Finance and Technology Group Limited</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signature;
