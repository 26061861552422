import React from "react";
import "./questionnaire.css"; // Custom CSS for any additional styling

const FinanceForm = ({ qus }) => {
  return (
    <div className="container mt-4">
      <h1 className="h5 mb-4">Let's understand your finance!</h1>
      {qus &&
        qus?.map((qus, index) => {
          return (
            <div key={index} className="card mb-4">
              <div className="card-body">
                <p>Q.{qus?.question?.order}</p>
                <div className="question mb-3">{qus?.question?.question}</div>

                {qus?.answer?.map((ans, i) => {
                  const isAudio =
                    typeof ans === "string" &&
                    ans.toLowerCase().endsWith(".mp3");

                  return (
                    <div key={i} className="answer p-3 rounded mb-3">
                      {isAudio ? (
                        <audio controls>
                          <source src={ans} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      ) : (
                        <div>{ans}</div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default FinanceForm;
