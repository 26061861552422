// import React, { useEffect, useState } from "react";
// import { FaEdit, FaSave, FaTrash } from "react-icons/fa";
// import { Spinner } from "react-bootstrap";
// import { useParams } from "react-router";
// import { toast } from "react-toastify";

// const DirectStaffTable = ({
//   heading,
//   col1,
//   col2,
//   col3,
//   col4,
//   col5,
//   col6,
//   data,
//   fetchAllData,
//   isEditMode = false,
//   sideBarKey,
// }) => {
//   const [editingRows, setEditingRows] = useState([]);
//   const [loadingRows, setLoadingRows] = useState([]);
//   const [tableData, setTableData] = useState([]);
//   const { userid, year } = useParams();

//   useEffect(() => {
//     const initialRows = new Array(10).fill({
//       id: null,
//       name: "",
//       project: "",
//       involvement: "",
//       rdPercentage: "",
//       salary: "",
//     });

//     const formattedData = (
//       data?.map((item) => ({
//         id: item.id,
//         name: item.name,
//         project: item.projects,
//         involvement: item.involvement_responsibility,
//         rdPercentage: item.percentage_of_time_spent_on_rd_project,
//         salary: item.total_yearly_salary,
//         sheet_type: item.sheet_type,
//       })) || []
//     ).concat(initialRows);

//     setTableData(formattedData.slice(0, 10));
//   }, [data]);

//   const handleEditClick = (index) => {
//     setEditingRows((prev) => {
//       const updated = new Array(prev.length).fill(false);
//       updated[index] = true;
//       return updated;
//     });
//   };

//   const handleSaveClick = async (index) => {
//     setLoadingRows((prev) => {
//       const updated = [...prev];
//       updated[index] = true;
//       return updated;
//     });

//     const row = tableData[index];
//     const bodyData = {
//       name: row?.name,
//       projects: row?.project,
//       involvement_responsibility: row?.involvement,
//       percentage_of_time_spent_on_rd_project: row?.rdPercentage,
//       total_yearly_salary: row?.salary,
//       sheet_type: sideBarKey,
//       year: year,
//       user: userid,
//     };

//     try {
//       const url = row.id
//         ? `${process.env.REACT_APP_BASEURL}workbooks/${row.id}/`
//         : `${process.env.REACT_APP_BASEURL}workbooks/`;

//       const method = row.id ? "PATCH" : "POST";

//       const response = await fetch(url, {
//         method: method,
//         headers: {
//           "Content-Type": "application/json",
//           "X-CSRFToken":
//             "dGiYiWj9URCjX2wDiexhekM4K3PV3Yfknpj8MEJynsF96rJ7gDqAs4BG35Sxvw5b",
//         },
//         body: JSON.stringify(bodyData),
//       });

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       setTimeout(() => {
//         setLoadingRows((prev) => {
//           const updated = [...prev];
//           updated[index] = false;
//           return updated;
//         });
//         setEditingRows((prev) => {
//           const updated = [...prev];
//           updated[index] = false;
//           return updated;
//         });
//         fetchAllData();
//       }, 1000); // Simulating a 1-second save operation
//     } catch (error) {
//       console.error("Failed to save data:", error);
//       setLoadingRows((prev) => {
//         const updated = [...prev];
//         updated[index] = false;
//         return updated;
//       });
//     }
//   };

//   const handleInputChange = (index, field, value) => {
//     setTableData((prev) => {
//       const updatedTableData = [...prev];
//       updatedTableData[index] = {
//         ...updatedTableData[index],
//         [field]: value,
//       };
//       return updatedTableData;
//     });
//   };

//   const handleAddRow = () => {
//     const newRow = {
//       id: null,
//       name: "",
//       project: "",
//       involvement: "",
//       rdPercentage: "",
//       salary: "",
//     };

//     setTableData((prev) => {
//       const updatedData = [...prev, newRow];
//       setEditingRows(new Array(updatedData.length).fill(false));
//       setEditingRows((prevEditingRows) => {
//         const updatedEditingRows = [...prevEditingRows];
//         updatedEditingRows[updatedData.length - 1] = true;
//         return updatedEditingRows;
//       });
//       return updatedData;
//     });
//   };

//   const handleDeleteRow = (index) => {
//     setTableData((prev) => {
//       const updatedTableData = [...prev];
//       updatedTableData.splice(index, 1);
//       return updatedTableData;
//     });

//     setEditingRows((prev) => {
//       const updatedEditingRows = [...prev];
//       updatedEditingRows.splice(index, 1);
//       return updatedEditingRows;
//     });

//     setLoadingRows((prev) => {
//       const updatedLoadingRows = [...prev];
//       updatedLoadingRows.splice(index, 1);
//       return updatedLoadingRows;
//     });
//   };

//   const [dataInvo, setInvoData] = useState(null);
//   const [dataproject, setprojectData] = useState(null);

//   const fetchProjectData = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASEURL}project/?user_id=${userid}`
//       );

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       const result = await response.json();
//       setprojectData(result);
//     } catch (error) {
//       console.log(error.message);
//     }
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_BASEURL}involvement/`
//         );

//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }

//         const result = await response.json(); // Parse JSON response
//         setInvoData(result); // Set the data
//       } catch (error) {
//         console.log(error.message); // Set error if any
//       }
//     };

//     fetchData();
//     fetchProjectData();
//   }, []);

//   const UpdateStatusButton = () => {
//     const apiUrl = `https://api.agfinancehub.com/api/user/${userid}/`;

//     fetch(apiUrl, {
//       method: "PATCH",
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//       },
//       body: "workbookstatus=true",
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log("Success:", data);
//         toast.success("Success Complete");
//         // You can update the state here if needed
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   };

//   return (
//     <div className="container mt-4">
//       <h2 className="text-center bg-warning text-dark py-2">{heading}</h2>
//       <div style={{ overflowX: "auto" }}>
//         <table className="table table-bordered mt-4">
//           <thead className="bg-warning text-center">
//             <tr>
//               {isEditMode && <th>Action</th>}
//               <th>{col1}</th>
//               <th>{col2}</th>
//               <th>{col3}</th>
//               <th>{col5}</th>
//               <th>{col6}</th>
//             </tr>
//           </thead>
//           <tbody>
//             {tableData &&
//               tableData.map((row, index) => (
//                 <tr key={index}>
//                   {isEditMode && (
//                     <td>
//                       <div className="d-flex justify-content-around">
//                         {loadingRows[index] ? (
//                           <Spinner animation="border" role="status" size="sm" />
//                         ) : editingRows[index] ? (
//                           <>
//                             <button
//                               className="btn btn-sm btn-success"
//                               onClick={() => handleSaveClick(index)}
//                             >
//                               Submit
//                             </button>
//                             <button
//                               className="btn btn-sm btn-danger"
//                               onClick={() => handleDeleteRow(index)}
//                             >
//                               Delete
//                             </button>
//                           </>
//                         ) : (
//                           <button
//                             className="btn btn-sm btn-warning"
//                             onClick={() => handleEditClick(index)}
//                           >
//                             Edit
//                           </button>
//                         )}
//                       </div>
//                     </td>
//                   )}
//                   <td>
//                     <input
//                       type="text"
//                       className="form-control"
//                       value={row.name}
//                       onChange={(e) =>
//                         handleInputChange(index, "name", e.target.value)
//                       }
//                       disabled={!editingRows[index]}
//                     />
//                   </td>
//                   <td>
//                     <select
//                       className="form-select"
//                       value={row.project}
//                       onChange={(e) =>
//                         handleInputChange(index, "project", e.target.value)
//                       }
//                       disabled={!editingRows[index]}
//                     >
//                       <option>Select Project</option>
//                       {dataproject &&
//                         dataproject?.map((item, index) => {
//                           return (
//                             <option key={index} value={item.id}>
//                               {item.name}
//                             </option>
//                           );
//                         })}
//                       {/* Add more options as needed */}
//                     </select>
//                   </td>
//                   <td>
//                     <select
//                       className="form-select"
//                       value={row.involvement}
//                       onChange={(e) =>
//                         handleInputChange(index, "involvement", e.target.value)
//                       }
//                       disabled={!editingRows[index]}
//                     >
//                       <option>Select Responsibility</option>
//                       {dataInvo &&
//                         dataInvo?.map((item, index) => {
//                           return (
//                             <option key={index} value={item.id}>
//                               {item.name}
//                             </option>
//                           );
//                         })}
//                     </select>
//                   </td>
//                   <td>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Enter %"
//                       value={row.rdPercentage}
//                       onChange={(e) =>
//                         handleInputChange(index, "rdPercentage", e.target.value)
//                       }
//                       disabled={!editingRows[index]}
//                     />
//                   </td>
//                   <td>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Enter Salary"
//                       value={row.salary}
//                       onChange={(e) =>
//                         handleInputChange(index, "salary", e.target.value)
//                       }
//                       disabled={!editingRows[index]}
//                     />
//                   </td>
//                 </tr>
//               ))}
//           </tbody>
//         </table>
//       </div>
//       <div className="text-center">
//         <button className="btn btn-success" onClick={handleAddRow}>
//           Add More
//         </button>

//         <button className="btn btn-primary" onClick={UpdateStatusButton}>
//           Complete
//         </button>
//       </div>
//     </div>
//   );
// };

// export default DirectStaffTable;
import React, { useEffect, useState } from "react";
import { FaEdit, FaSave, FaTrash } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { toast } from "react-toastify";

const DirectStaffTable = ({
  heading,
  col1,
  col2,
  col3,
  col4,
  col5,
  col6,
  data,
  fetchAllData,
  isEditMode = false,
  sideBarKey,
  invoData,
  projectData,
}) => {
  const [editingRow, setEditingRow] = useState(null); // Track only one row being edited
  const [loadingRows, setLoadingRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(""); // To store the error message
  const { userid, year } = useParams();

  useEffect(() => {
    const initialRows = new Array(10).fill({
      id: null,
      name: "",
      project: "",
      involvement: "",
      rdPercentage: "",
      salary: "",
    });

    const formattedData = (
      data?.map((item) => ({
        id: item.id,
        name: item.name,
        project: item.projects,
        involvement: item.involvement_responsibility,
        rdPercentage: item.percentage_of_time_spent_on_rd_project,
        salary: item.total_yearly_salary,
        sheet_type: item.sheet_type,
      })) || []
    ).concat(initialRows);

    setTableData(formattedData.slice(0, 10));
  }, [data]);

  const handleEditClick = (index) => {
    if (editingRow !== null && editingRow !== index) {
      // If another row is being edited, prevent editing this row
      setError(
        "Please submit or delete the current row before editing another one."
      );
      return;
    }

    setEditingRow(index);
    setError(""); // Clear error if editing row is allowed
  };

  const validateRow = (row) => {
    if (
      !row.name ||
      !row.project ||
      !row.involvement ||
      !row.rdPercentage ||
      !row.salary
    ) {
      return false;
    }
    return true;
  };

  const handleSaveClick = async (index) => {
    const row = tableData[index];

    // Validate if all fields are filled
    if (!validateRow(row)) {
      setError("Please fill all the fields before saving.");
      return;
    } else {
      setError(""); // Clear error message if validation passes
    }

    setLoadingRows((prev) => {
      const updated = [...prev];
      updated[index] = true;
      return updated;
    });

    const bodyData = {
      name: row?.name,
      projects: row?.project,
      involvement_responsibility: row?.involvement,
      percentage_of_time_spent_on_rd_project: row?.rdPercentage,
      total_yearly_salary: row?.salary,
      sheet_type: sideBarKey,
      year: year,
      user: userid,
    };

    try {
      const url = row.id
        ? `${process.env.REACT_APP_BASEURL}workbooks/${row.id}/`
        : `${process.env.REACT_APP_BASEURL}workbooks/`;

      const method = row.id ? "PATCH" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken":
            "dGiYiWj9URCjX2wDiexhekM4K3PV3Yfknpj8MEJynsF96rJ7gDqAs4BG35Sxvw5b",
        },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setTimeout(() => {
        setLoadingRows((prev) => {
          const updated = [...prev];
          updated[index] = false;
          return updated;
        });
        setEditingRow(null); // Reset editing row after save
        fetchAllData();
      }, 1000); // Simulating a 1-second save operation
    } catch (error) {
      console.error("Failed to save data:", error);
      setLoadingRows((prev) => {
        const updated = [...prev];
        updated[index] = false;
        return updated;
      });
    }
  };

  const handleInputChange = (index, field, value) => {
    setTableData((prev) => {
      const updatedTableData = [...prev];
      updatedTableData[index] = {
        ...updatedTableData[index],
        [field]: value,
      };
      return updatedTableData;
    });
  };

  const handleAddRow = () => {
    const newRow = {
      id: null,
      name: "",
      project: "",
      involvement: "",
      rdPercentage: "",
      salary: "",
    };

    setTableData((prev) => {
      const updatedData = [...prev, newRow];
      setEditingRow(updatedData.length - 1); // Start editing the new row
      return updatedData;
    });
  };

  const handleDeleteRow = (index) => {
    setTableData((prev) => {
      const updatedTableData = [...prev];
      updatedTableData.splice(index, 1);
      return updatedTableData;
    });

    setLoadingRows((prev) => {
      const updatedLoadingRows = [...prev];
      updatedLoadingRows.splice(index, 1);
      return updatedLoadingRows;
    });

    setEditingRow(null); // Reset editing row when deleted
  };

  const UpdateStatusButton = () => {
    const apiUrl = `https://api.agfinancehub.com/api/user/${userid}/`;

    fetch(apiUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "workbookstatus=true",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        toast.success("Success Complete");
        // You can update the state here if needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center bg-warning text-dark py-2">{heading}</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <div style={{ overflowX: "auto" }}>
        <table className="table table-bordered mt-4">
          <thead className="bg-warning text-center">
            <tr>
              {isEditMode && <th>Action</th>}
              <th>{col1}</th>
              <th>{col2}</th>
              <th>{col3}</th>
              <th>{col5}</th>
              <th>{col6}</th>
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((row, index) => (
                <tr key={index}>
                  {isEditMode && (
                    <td>
                      <div className="d-flex justify-content-around">
                        {loadingRows[index] ? (
                          <Spinner animation="border" role="status" size="sm" />
                        ) : editingRow === index ? (
                          <>
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => handleSaveClick(index)}
                            >
                              Submit
                            </button>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => handleDeleteRow(index)}
                            >
                              Delete
                            </button>
                          </>
                        ) : (
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => handleEditClick(index)}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </td>
                  )}
                  <td>
                    {editingRow === index ? (
                      <input
                        type="text"
                        className="form-control"
                        value={row.name}
                        onChange={(e) =>
                          handleInputChange(index, "name", e.target.value)
                        }
                      />
                    ) : (
                      row.name
                    )}
                  </td>
                  <td>
                    {editingRow === index ? (
                      <select
                        className="form-control"
                        value={row.project}
                        onChange={(e) =>
                          handleInputChange(index, "project", e.target.value)
                        }
                      >
                        <option value="">Select Project</option>
                        {projectData?.map((project, i) => (
                          <option key={i} value={project.name}>
                            {project.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      row.project
                    )}
                  </td>
                  <td>
                    {editingRow === index ? (
                      <select
                        className="form-control"
                        value={row.involvement}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "involvement",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Involvement</option>
                        {invoData?.map((involvement, i) => (
                          <option key={i} value={involvement.name}>
                            {involvement.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      row.involvement
                    )}
                  </td>
                  <td>
                    {editingRow === index ? (
                      <input
                        type="text"
                        className="form-control"
                        value={row.rdPercentage}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "rdPercentage",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      row.rdPercentage
                    )}
                  </td>
                  <td>
                    {editingRow === index ? (
                      <input
                        type="text"
                        className="form-control"
                        value={row.salary}
                        onChange={(e) =>
                          handleInputChange(index, "salary", e.target.value)
                        }
                      />
                    ) : (
                      row.salary
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {isEditMode && (
          <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-primary" onClick={handleAddRow}>
              Add Row
            </button>
            <button className="btn btn-info" onClick={UpdateStatusButton}>
              Update Status
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectStaffTable;
