import { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { BsCalendar2MonthFill } from "react-icons/bs";
import { toast } from "react-toastify";

function EditLoe({
  showModal,
  setShowModal,
  editData = null,
  setEditData = null,
  UserData = null,
  fetchLoeData,
  loeData,
  userType,
  businessItem,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setValue("company_name", UserData?.company_name);
    setValue("name", UserData?.full_name);

    if (businessItem?.company_info?.role == "tax") {
      setValue("company_name", UserData?.company_name);
      setValue("name", UserData?.full_name);
      setValue("no", businessItem?.company_info?.no);
      setValue("address1", businessItem?.company_info?.address1);
      setValue("address2", businessItem?.company_info?.address2);
      setValue("address3", businessItem?.company_info?.address3);
      setValue("address4", businessItem?.company_info?.address4);
      setValue("date", businessItem?.company_info);
      setValue("percentage", businessItem?.company_info?.percentage);
      setValue("year", businessItem?.company_info?.year);
      setValue("user", businessItem?.user);
    } else {
      setValue("company_name", UserData?.company_name);
      setValue("name", UserData?.full_name);
      setValue("no", businessItem?.company_info?.no);
      setValue("address1", businessItem?.company_info?.address1);
      setValue("address2", businessItem?.company_info?.address2);
      setValue("address3", businessItem?.company_info?.address3);
      setValue("address4", businessItem?.company_info?.address4);
      setValue("date", businessItem?.company_info);
      setValue("percentage", businessItem?.company_info?.percentage);
      setValue("year", businessItem?.company_info?.year);
      setValue("user", businessItem?.user);
    }
  }, [editData, setValue]);

  const submitHandler = async (data) => {
    setLoading(true);
    try {
      const formattedDate = new Date().toLocaleDateString();
      const formatYearsBetween = (dates) => {
        // Extract years and months from the given dates
        const startDate = new Date(dates[0]);
        const endDate = new Date(dates[1]);

        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        const startMonth = startDate.toLocaleString("en-US", { month: "long" });

        const formattedYears = [];

        for (let year = startYear; year <= endYear; year++) {
          const month = year === startYear ? startMonth : startMonth; // Keep the same month
          formattedYears.push(`${month} ${year}`);
        }

        return formattedYears.join(", ");
      };

      const dataAll = {
        company_name: data?.company_name,
        name: data.name,
        no: data?.no,
        address1: data?.address1,
        address2: data?.address2,
        address3: data?.address3,
        address4: data?.address4,
        percentage: data?.percentage,
        date: formattedDate,
        duration:
          formatYearsBetween(data?.duration) ||
          loeData?.results[0]?.company_info?.duration,
        year: "2024-2025",
        user: UserData?.id,
        role: userType == "taxrelief" || userType == "tax" ? "tax" : "business",
      };

      const url = businessItem?.company_info
        ? `${process.env.REACT_APP_BASEURL}loe/${businessItem.company_info.id}/`
        : `${process.env.REACT_APP_BASEURL}loe/`;

      const method = businessItem?.company_info ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataAll),
      });

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }
      if (response) {
        setShowModal(false);
        setEditData && setEditData(null);
        const tosters = loeData?.results[0]?.company_info
          ? "LOE Update successfull"
          : "LOE Add successfull";
        toast.success(tosters);
        fetchLoeData();
      }
    } catch (error) {
      console.error("Submission Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const currentYear = new Date().getFullYear();
  const currentYearEnd = currentYear + 1;

  const yearOptions = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => {
    const yearStart = 2000 + i;
    const yearEnd = yearStart + 1;
    return `${yearStart}-${yearEnd}`;
  });

  return (
    <Modal
      show={showModal}
      onHide={handleCancel}
      centered
      backdrop="static"
      id="modal-container"
    >
      <Modal.Title className="bg-dark pt-2 pb-1">
        <h5 className="text-center text-light">
          {loeData?.results[0]?.company_info?.role
            ? "Update LOE"
            : "Generate LOE"}
        </h5>
      </Modal.Title>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formCompanyName">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  className="mb-3"
                  {...register("company_name", { required: true })}
                />
                {errors?.company_name && (
                  <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                    This field is required
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  className="mb-3"
                  {...register("name", { required: true })}
                />
                {errors?.name && (
                  <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                    This field is required
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formNo">
                <Form.Label>Registration Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter number"
                  className="mb-3"
                  {...register("no", { required: true })}
                />
                {errors?.no && (
                  <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                    This field is required
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formAddress1">
                <Form.Label>Address Line 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter address 1"
                  className="mb-3"
                  {...register("address1", { required: true })}
                />
                {errors?.address1 && (
                  <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                    This field is required
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formAddress2">
                <Form.Label>City/Town</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter address 2"
                  className="mb-3"
                  {...register("address2", { required: true })}
                />
                {errors?.address2 && (
                  <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                    This field is required
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formAddress3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter address 3"
                  className="mb-3"
                  {...register("address3", { required: true })}
                />
                {errors?.address3 && (
                  <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                    This field is required
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formAddress4">
                <Form.Label>Post Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter address 4"
                  className="mb-3"
                  {...register("address4", { required: true })}
                />
                {errors?.address4 && (
                  <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                    This field is required
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formPercentage">
                <Form.Label>Percentage</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter percentage"
                  className="mb-3"
                  {...register("percentage", {
                    required: "This field is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Only numbers are allowed",
                    },
                    validate: (value) =>
                      (parseInt(value) >= 1 && parseInt(value) <= 100) ||
                      "Percentage must be between 1 and 100",
                  })}
                />
                {errors?.percentage && (
                  <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                    {errors.percentage.message}
                  </p>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formDuration">
                <Form.Label>Duration</Form.Label>
                <Controller
                  name="duration"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DateRangePicker
                      {...field}
                      format="MMM yyyy"
                      placeholder="Select start and end year"
                      caretAs={BsCalendar2MonthFill}
                      className="mb-3"
                      appearance="default"
                      oneTap={false}
                      onChange={(value) => field.onChange(value)}
                      style={{ width: "100%" }}
                      menuStyle={{ zIndex: 1051 }}
                      placement="autoVerticalStart"
                    />
                  )}
                />
                {errors.duration && (
                  <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                    This field is required
                  </p>
                )}
              </Form.Group>
            </Col>
            {/* <Col md={6}>
              <Form.Group controlId="formYear">
                <Form.Label>Year</Form.Label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  {...register("year", { required: true })}
                >
                  <option value="" disabled>
                    Select Year
                  </option>
                  {yearOptions &&
                    yearOptions?.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                </select>
                {errors?.year && (
                  <p style={{ color: "red", fontSize: "14px", margin: "0" }}>
                    This field is required
                  </p>
                )}
              </Form.Group>
            </Col> */}
            <Col md={6}>
              <Form.Group controlId="formAddress4">
                <Form.Label>Year</Form.Label>
                <Form.Control
                  type="text"
                  // placeholder="Enter Year"
                  className="mb-3"
                  value={"2024-2025"}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit(submitHandler)}
          disabled={loading}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditLoe;
