import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeader1 from "../../components/common/PageHeader1";
import { Tab, Nav } from "react-bootstrap";
import BackIcon from "../../assets/images/back-btn.svg";
import WorkBook from "./components/workbook";

import { fetchData } from "../../helper/apiFunction";
import { MdPerson, MdDescription, MdMeetingRoom } from "react-icons/md";
import { FaBook } from "react-icons/fa";

const ViewUser = () => {
  const navigate = useNavigate();
  const { userid, year } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("tabNew6");
  const [activeSubKey, setActiveSubKey] = useState("subSection1");
  const [selectedYear, setSelectedYear] = useState("2023-2024");
  // workbook data
  const [taxReliefData, setTaxReliefData] = useState(null);
  const [directStaffData, setDirectStaffData] = useState([]);
  const [subcontractsData, setSubcontractsData] = useState([]);
  const [consumablesData, setConsumablesData] = useState([]);
  const [itSoftwareData, setItSoftwareData] = useState([]);
  const [sideBarKey, setSideBar] = useState("directstaff");
  const currentYear = new Date().getFullYear();
  const currentYearEnd = currentYear + 1;
  // Generate year options from 2000-01 up to the current year
  const yearOptions = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => {
    const yearStart = 2000 + i;
    const yearEnd = yearStart + 1;
    return `${yearStart}-${yearEnd}`;
  });

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const fetchAllData = async () => {
    try {
      const [taxRelief, directStaff, subcontracts, consumables, itSoftware] =
        await Promise.all([
          fetchData(`tax-user/${userid}`),
          fetchData(
            `workbooks/?year=${year}&sheet_type=directstaff&user_id=${userid}`,
            "GET"
          ),
          fetchData(
            `workbooks/?year=${year}&sheet_type=subcontracts&user_id=${userid}`,
            "GET"
          ),
          fetchData(
            `workbooks/?year=${year}&sheet_type=consumables&user_id=${userid}`,
            "GET"
          ),
          fetchData(
            `workbooks/?year=${year}&sheet_type=itsoftwarecosts&user_id=${userid}`,
            "GET"
          ),
        ]);

      setTaxReliefData(taxRelief);
      setDirectStaffData(directStaff);
      setSubcontractsData(subcontracts);
      setConsumablesData(consumables);
      setItSoftwareData(itSoftware);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <div className="d-flex align-items-center">
          <PageHeader1
            pagetitle="WorkBook"
            modalbutton={() => {
              return <div className="col-auto d-flex w-sm-100"></div>;
            }}
          />
        </div>
        <div className="row clearfix g-3">
          <div className="col-sm-12 mt-3">
            <div className="card">
              <div className="card-body">
                <div
                  id="myProjectTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      {isLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "70vh" }}
                        >
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="tabbing-box">
                          <Tab.Container
                            id="left-tabs-example"
                            activeKey={activeKey}
                            onSelect={setActiveKey}
                          >
                            <Tab.Content className="tab-content-custom">
                              {/*  <div className="text-center headingmain">
                                <h2>Workbook</h2>
                              </div> */}

                              <div className="nav-container">
                                {/*   <Nav variant="tabs" className="nav-tabs-right">
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="tabNew6"
                                      className="d-flex align-items-center"
                                      onClick={() => setActiveKey("tabNew6")}
                                    >
                                      {<FaBook className="me-2 fs-4" />}
                                      Workbook
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav> */}

                                {/* Subsections for Workbook */}
                                {activeKey === "tabNew6" && selectedYear && (
                                  <Nav variant="tabs" className="sub-nav-right">
                                    <Nav.Item>
                                      <Nav.Link
                                        className={`sub-nav-link-custom ${
                                          activeSubKey === "subSection1"
                                            ? "active-sub"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          setActiveSubKey("subSection1");
                                          setSideBar("directstaff");
                                        }}
                                      >
                                        Direct Staff
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className={`sub-nav-link-custom ${
                                          activeSubKey === "subSection2"
                                            ? "active-sub"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          setActiveSubKey("subSection2");
                                          setSideBar("subcontracts");
                                        }}
                                      >
                                        Sub-contractors
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className={`sub-nav-link-custom ${
                                          activeSubKey === "subSection3"
                                            ? "active-sub"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          setActiveSubKey("subSection3");
                                          setSideBar("consumables");
                                        }}
                                      >
                                        Consumables
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className={`sub-nav-link-custom ${
                                          activeSubKey === "subSection4"
                                            ? "active-sub"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          setActiveSubKey("subSection4");
                                          setSideBar("itsoftwarecosts");
                                        }}
                                      >
                                        IT & Software Costs
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className={`sub-nav-link-custom ${
                                          activeSubKey === "subSection5"
                                            ? "active-sub"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          setActiveSubKey("subSection5");
                                          setSideBar("Other");
                                        }}
                                      >
                                        Other
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                )}
                              </div>
                              <Tab.Pane eventKey="tabNew6">
                                {/* <div>Main Workbook Content</div> */}
                                {activeSubKey === "subSection1" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`Direct Staff 2024-2025`}
                                      col1="Name"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      data={directStaffData}
                                      isEditMode={true}
                                      fetchAllData={fetchAllData}
                                      sideBarKey={sideBarKey}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection2" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`FINANCIAL YEAR 2024-2025`}
                                      col1="Name"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      data={subcontractsData}
                                      isEditMode={true}
                                      fetchAllData={fetchAllData}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection3" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`FINANCIAL YEAR 2024-2025`}
                                      col1="Name"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      data={consumablesData}
                                      isEditMode={true}
                                      fetchAllData={fetchAllData}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection4" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`FINANCIAL YEAR 2024-2025`}
                                      col1="Name"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      data={itSoftwareData}
                                      isEditMode={true}
                                      fetchAllData={fetchAllData}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection5" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`FINANCIAL YEAR 2024-2025`}
                                      col1="Name"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      data={itSoftwareData}
                                      isEditMode={true}
                                      fetchAllData={fetchAllData}
                                    />
                                  </div>
                                )}
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
