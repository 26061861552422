import React, { useEffect, useState } from "react";
import { FaEdit, FaSave, FaTrash } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import WorkSendModel from "./WorkSendModel";
import { toast } from "react-toastify";

const DirectStaffTable = ({
  heading,
  col1,
  col2,
  col3,
  col4,
  col5,
  col6,
  data,
  fetchAllData,
  isEditMode = false,
  sideBarKey,
  UserData,
  selectedYear,
}) => {
  const [editingRows, setEditingRows] = useState([]);
  const [loadingRows, setLoadingRows] = useState([]);
  const [tableData, setTableData] = useState([]);

  const year = selectedYear;

  useEffect(() => {
    const formattedData =
      data &&
      data?.map((item) => ({
        id: item.id,
        name: item.name,
        project: item.projects,
        involvement: item.involvement_responsibility,
        notes: item?.notes,
        rdPercentage: item.percentage_of_time_spent_on_rd_project,
        salary: item.total_yearly_salary,
        sheet_type: item.sheet_type,
      }));
    setTableData(formattedData);
  }, [data]);

  const handleEditClick = (index) => {
    setEditingRows((prev) => {
      const updated = new Array(prev.length).fill(false);
      updated[index] = true;
      return updated;
    });
  };

  const handleSaveClick = async (index) => {
    setLoadingRows((prev) => {
      const updated = [...prev];
      updated[index] = true;
      return updated;
    });

    const row = tableData[index];
    const bodyData = {
      name: row?.name,
      projects: row?.project,
      involvement_responsibility: row?.involvement,
      notes: row?.notes,
      percentage_of_time_spent_on_rd_project: row?.rdPercentage,
      total_yearly_salary: row?.salary,
      sheet_type: sideBarKey,
      year: year,
      user: UserData?.id,
    };

    try {
      const url = row.id
        ? `${process.env.REACT_APP_BASEURL}workbooks/${row.id}/`
        : `${process.env.REACT_APP_BASEURL}workbooks/`;
      const method = row.id ? "PATCH" : "POST";
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken":
            "dGiYiWj9URCjX2wDiexhekM4K3PV3Yfknpj8MEJynsF96rJ7gDqAs4BG35Sxvw5b",
        },
        body: JSON.stringify(bodyData),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setTimeout(() => {
        setLoadingRows((prev) => {
          const updated = [...prev];
          updated[index] = false;
          return updated;
        });
        setEditingRows((prev) => {
          const updated = [...prev];
          updated[index] = false;
          return updated;
        });
        fetchAllData();
      }, 1000);
    } catch (error) {
      console.error("Failed to save data:", error);
      setLoadingRows((prev) => {
        const updated = [...prev];
        updated[index] = false;
        return updated;
      });
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index][field] = value;
    setTableData(updatedTableData);
  };

  const handleAddRow = () => {
    const newRow = {
      id: null,
      name: "",
      project: "",
      involvement: "",
      notes: "",
      rdPercentage: "",
      salary: "",
    };

    setTableData((prev) => {
      const updatedData = [...prev, newRow];
      setEditingRows(new Array(updatedData.length).fill(false));
      setEditingRows((prevEditingRows) => {
        const updatedEditingRows = [...prevEditingRows];
        updatedEditingRows[updatedData.length - 1] = true;
        return updatedEditingRows;
      });
      return updatedData;
    });
  };

  const handleDeleteRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);

    const updatedEditingRows = [...editingRows];
    updatedEditingRows.splice(index, 1);
    setEditingRows(updatedEditingRows);

    const updatedLoadingRows = [...loadingRows];
    updatedLoadingRows.splice(index, 1);
    setLoadingRows(updatedLoadingRows);
  };

  const [dataInvo, setInvoData] = useState(null);
  const [dataproject, setprojectData] = useState(null);

  const fetchProjectData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}project/?user_id=${UserData?.id}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setprojectData(result);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}involvement/`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json(); // Parse JSON response
        setInvoData(result); // Set the data
      } catch (error) {
        console.log(error.message); // Set error if any
      }
    };

    fetchData();
    fetchProjectData();
  }, []);

  const UpdateStatusButton = () => {
    const apiUrl = `https://api.agfinancehub.com/api/user/${UserData?.id}/`;

    fetch(apiUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "workbookstatus=true",
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Success Complete");
        console.log("Success:", data);
        // You can update the state here if needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center bg-warning text-dark py-2">{heading}</h2>
      <div style={{ overflowX: "auto" }}>
        <table className="table table-bordered mt-4">
          <thead className="bg-warning text-center">
            <tr>
              {isEditMode && <th>Action</th>}
              <th>{col1}</th>
              <th>{col2}</th>
              <th>{col3}</th>
              <th>{col5}</th>
              <th>{col6}</th>
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData?.map((row, index) => (
                <tr key={index}>
                  {isEditMode && (
                    <td className="text-center">
                      {loadingRows[index] ? (
                        <Spinner animation="border" size="sm" />
                      ) : editingRows[index] ? (
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-success me-2"
                            onClick={() => handleSaveClick(index)}
                          >
                            Submit
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDeleteRow(index)}
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleEditClick(index)}
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  )}

                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={row.name}
                      onChange={(e) =>
                        handleInputChange(index, "name", e.target.value)
                      }
                      disabled={!editingRows[index]}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      value={row.project}
                      onChange={(e) =>
                        handleInputChange(index, "project", e.target.value)
                      }
                      disabled={!editingRows[index]}
                    >
                      <option>Select Project</option>
                      {dataproject &&
                        dataproject?.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      value={row.involvement}
                      onChange={(e) =>
                        handleInputChange(index, "involvement", e.target.value)
                      }
                      disabled={!editingRows[index]}
                    >
                      <option>Select Responsibility</option>
                      {dataInvo &&
                        dataInvo?.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}

                      {/* Add more options as needed */}
                    </select>
                  </td>

                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter %"
                      value={row.rdPercentage}
                      onChange={(e) =>
                        handleInputChange(index, "rdPercentage", e.target.value)
                      }
                      disabled={!editingRows[index]}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Spent"
                      value={row.salary}
                      onChange={(e) =>
                        handleInputChange(index, "salary", e.target.value)
                      }
                      disabled={!editingRows[index]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center gap-2 mt-3">
        {isEditMode && (
          <div className="text-center gap-2">
            <button className="btn btn-secondary" onClick={handleAddRow}>
              Add More
            </button>
            &nbsp; &nbsp;
            <button className="btn btn-primary" onClick={UpdateStatusButton}>
              Complete
            </button>
          </div>
        )}
        <WorkSendModel UserData={UserData} />
      </div>
    </div>
  );
};

export default DirectStaffTable;
