import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader1 from "../../components/common/PageHeader1";
import { Tab, Nav, Dropdown, DropdownButton } from "react-bootstrap";
import BackIcon from "../../assets/images/back-btn.svg";
import BasicInfo from "./components/basicInfo";
import DocumentUpload from "./components/uploadDocs";
import WorkBook from "./components/workbook";

import { LuClipboardSignature } from "react-icons/lu";
import Meetings from "./components/meetings";
import Questionnaire from "./components/questionnaire";
import PdfViewer from "./components/LOEviewer";
import { fetchData } from "../../helper/apiFunction";
import {
  MdPerson,
  MdDescription,
  MdMeetingRoom,
  MdOutlineStackedBarChart,
} from "react-icons/md";
import { FaFileSignature, FaClipboardList, FaBook } from "react-icons/fa";
import ApplicationStatus from "./components/ApplicationStatus";
import StatusFinalDocument from "./components/StatusFinalDocument";

const EditUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const UserData = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("tabNew1");
  const [activeSubKey, setActiveSubKey] = useState("");
  const [selectedYear, setSelectedYear] = useState("2024-2025");
  const [taxReliefData, setTaxReliefData] = useState({});
  const [directStaffData, setDirectStaffData] = useState([]);
  const [subcontractsData, setSubcontractsData] = useState([]);
  const [consumablesData, setConsumablesData] = useState([]);
  const [itSoftwareData, setItSoftwareData] = useState([]);
  const [activeQusSubKey, setActiveQusSubKey] = useState("subSection1");
  const [technicalData, setTechnicalData] = useState(null);
  const [discoveryData, setDiscoveryData] = useState(null);
  const [sideBarKey, setSideBar] = useState("directstaff");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownone, setShowDropdownone] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [projectData, setprojectData] = useState(false);
  const [discoveryCallId, setDiscoveryCallId] = useState(null);

  const currentYear = new Date().getFullYear();
  const currentYearEnd = currentYear + 1;
  const yearOptions = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => {
    const yearStart = 2000 + i;
    const yearEnd = yearStart + 1;
    return `${yearStart}-${yearEnd}`;
  });

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const fetchAllData = async () => {
    try {
      const [taxRelief, directStaff, subcontracts, consumables, itSoftware] =
        await Promise.all([
          fetchData(`tax-user/${UserData?.id}/`),
          fetchData(
            `workbooks/?year=2024-2025&sheet_type=directstaff&user_id=${UserData?.id}`,
            "GET"
          ),
          fetchData(
            `workbooks/?year=2024-2025&sheet_type=subcontracts&user_id=${UserData?.id}`,
            "GET"
          ),
          fetchData(
            `workbooks/?year=2024-2025&sheet_type=consumables&user_id=${UserData?.id}`,
            "GET"
          ),
          fetchData(
            `workbooks/?year=2024-2025&sheet_type=itsoftwarecosts&user_id=${UserData?.id}`,
            "GET"
          ),
        ]);

      setTaxReliefData(taxRelief);
      setDirectStaffData(directStaff);
      setSubcontractsData(subcontracts);
      setConsumablesData(consumables);
      setItSoftwareData(itSoftware);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, [UserData.id, selectedYear]);

  useEffect(() => {
    const fetchTechnicalData = async () => {
      try {
        const [discoveryData, technicalData] = await Promise.all([
          fetchData(
            `answers/?user_id=${UserData?.id}&category=Discovery Call Questionnaire`,
            "GET"
          ),
          discoveryCallId !== null
            ? fetchData(
                `answers/?user_id=${UserData?.id}&category=Technical Call Questionnaire&project=${discoveryCallId}`,
                "GET"
              )
            : Promise.resolve({ data: null }),
        ]);
        setTechnicalData(technicalData?.data);
        setDiscoveryData(discoveryData?.data);
        // setDiscoveryCallId(null);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTechnicalData();
  }, [discoveryCallId]);

  const handleNavClick1 = () => {
    setActiveQusSubKey("subSection2");
    setShowDropdownone(!showDropdownone);
  };

  const fetchProjectData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}project/?user_id=${UserData?.id}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result, "resultresult");
      setprojectData(result);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, [UserData.id]);

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <div className="d-flex align-items-center">
          <img
            src={BackIcon}
            alt="info"
            className="backbtn-icon me-3"
            style={{ width: "22px", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <PageHeader1
            pagetitle="Edit"
            modalbutton={() => {
              return <div className="col-auto d-flex w-sm-100 mb-0"></div>;
            }}
          />
        </div>
        <div className="row clearfix g-3">
          <div className="col-sm-12 mt-3">
            <div className="card">
              <div className="card-body">
                <div
                  id="myProjectTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      {isLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "70vh" }}
                        >
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="tabbing-box d-flex">
                          <Tab.Container
                            id="left-tabs-example"
                            activeKey={activeKey}
                            onSelect={setActiveKey}
                          >
                            <Tab.Content className="tab-content-custom">
                              <div className="nav-container mb-5">
                                <Nav variant="tabs" className="sub-nav-right">
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="tabNew1"
                                      className="sub-nav-link-custom"
                                    >
                                      <MdPerson className="me-2 fs-3" />
                                      Personal Info
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="tabNew2"
                                      className="sub-nav-link-custom"
                                    >
                                      <MdDescription className="me-2 fs-3" />
                                      Documents Uploaded
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="tabNew3"
                                      className="sub-nav-link-custom"
                                    >
                                      <MdMeetingRoom className="me-2 fs-3" />
                                      Meetings
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="tabNew4"
                                      className="sub-nav-link-custom"
                                    >
                                      <FaFileSignature className="me-2 fs-3" />
                                      LOE
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="tabNew48"
                                      className="sub-nav-link-custom"
                                    >
                                      <LuClipboardSignature className="me-2 fs-3" />
                                      Final Sign of Document
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="tabNew7"
                                      className="sub-nav-link-custom"
                                    >
                                      <MdOutlineStackedBarChart className="me-2 fs-3" />
                                      Tax Relief Status
                                    </Nav.Link>
                                  </Nav.Item>

                                  <Dropdown
                                    onToggle={(isOpen) =>
                                      isOpen &&
                                      setActiveKey(
                                        activeKey === "tabNew5"
                                          ? null
                                          : "tabNew5"
                                      )
                                    }
                                  >
                                    <Dropdown.Toggle className="sub-nav-link-custom mb-3">
                                      <FaClipboardList className="me-2 fs-3" />
                                      Questionnaire
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu
                                      style={{ padding: "5px" }}
                                      show={activeKey === "tabNew5"}
                                    >
                                      <Dropdown.Item
                                        className={`sub-nav-link-custom2 mb-2 ${
                                          activeQusSubKey === "subSection1"
                                            ? "active-sub"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          setActiveQusSubKey("subSection1");
                                          setShowSubMenu(false);
                                          setActiveKey("tabNew5");
                                        }}
                                      >
                                        Discover call
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        className={`sub-nav-link-custom2 ${
                                          activeQusSubKey === "subSection2"
                                            ? "active-sub"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          setActiveQusSubKey("subSection2");
                                          setShowSubMenu(true); // Open submenu directly when clicked
                                          handleNavClick1();
                                          setActiveKey("tabNew5"); // Keep dropdown open
                                        }}
                                      >
                                        Technical call
                                      </Dropdown.Item>

                                      {showSubMenu && (
                                        <div className="submenu-container">
                                          {projectData &&
                                            projectData.map((item) => {
                                              return (
                                                <Nav.Item key={item.id}>
                                                  <Nav.Link
                                                    className={`sub-nav-link-custom2 mb-1 ${
                                                      item.id ===
                                                      discoveryCallId
                                                        ? "active-sub"
                                                        : ""
                                                    }`}
                                                    onClick={() => {
                                                      setDiscoveryCallId(
                                                        item.id
                                                      );
                                                      // Optionally close the submenu after selection
                                                      // setShowSubMenu(false); // Uncomment to close submenu after selection
                                                    }}
                                                  >
                                                    {item.name}
                                                  </Nav.Link>
                                                </Nav.Item>
                                              );
                                            })}
                                        </div>
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>

                                  <Dropdown
                                    onToggle={(isOpen) =>
                                      isOpen &&
                                      setActiveKey(
                                        activeKey === "tabNew6"
                                          ? null
                                          : "tabNew6"
                                      )
                                    }
                                  >
                                    <Dropdown.Toggle className="sub-nav-link-custom mt-2">
                                      <FaBook className="me-2 fs-3" />
                                      Workbook
                                    </Dropdown.Toggle>
                                    <input type="text" value="2024-2025" />
                                    <Dropdown.Menu
                                      style={{ padding: "5px" }}
                                      show={activeKey === "tabNew6"}
                                    >
                                      <input type="text" value="2024-2025" />

                                      <div className="subsections2">
                                        <Dropdown.Item
                                          className={`sub-nav-link-custom2 mb-1 ${
                                            activeSubKey === "subSection1"
                                              ? "active-sub"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setActiveSubKey("subSection1");
                                            setActiveKey("tabNew6"); // Keep dropdown open
                                          }}
                                        >
                                          Direct Staff
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className={`sub-nav-link-custom2 mb-1 ${
                                            activeSubKey === "subSection2"
                                              ? "active-sub"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setActiveSubKey("subSection2");
                                            setActiveKey("tabNew6"); // Keep dropdown open
                                          }}
                                        >
                                          Sub-contractors
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className={`sub-nav-link-custom2 mb-1 ${
                                            activeSubKey === "subSection3"
                                              ? "active-sub"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setActiveSubKey("subSection3");
                                            setActiveKey("tabNew6"); // Keep dropdown open
                                          }}
                                        >
                                          Consumables
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className={`sub-nav-link-custom2 mb-1 ${
                                            activeSubKey === "subSection4"
                                              ? "active-sub"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setActiveSubKey("subSection4");
                                            setActiveKey("tabNew6"); // Keep dropdown open
                                          }}
                                        >
                                          IT & Software Costs
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className={`sub-nav-link-custom2 mb-1 ${
                                            activeSubKey === "subSection5"
                                              ? "active-sub"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setActiveSubKey("subSection5");
                                            setActiveKey("tabNew6"); // Keep dropdown open
                                          }}
                                        >
                                          Other
                                        </Dropdown.Item>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Nav>
                              </div>

                              <Tab.Pane eventKey="tabNew1">
                                {taxReliefData &&
                                  Object.keys(taxReliefData).length > 0 && (
                                    <BasicInfo
                                      mode={"edit"}
                                      data={taxReliefData}
                                    />
                                  )}
                              </Tab.Pane>
                              <Tab.Pane eventKey="tabNew2">
                                {taxReliefData &&
                                  Object.keys(taxReliefData).length > 0 && (
                                    <DocumentUpload UserData={taxReliefData} />
                                  )}
                              </Tab.Pane>
                              <Tab.Pane eventKey="tabNew3">
                                {taxReliefData &&
                                  Object.keys(taxReliefData).length > 0 && (
                                    <Meetings UserData={taxReliefData} />
                                  )}
                              </Tab.Pane>
                              <Tab.Pane eventKey="tabNew4">
                                {taxReliefData &&
                                  Object.keys(taxReliefData).length > 0 && (
                                    <PdfViewer UserData={taxReliefData} />
                                  )}
                              </Tab.Pane>
                              <Tab.Pane eventKey="tabNew7">
                                {taxReliefData &&
                                  Object.keys(taxReliefData).length > 0 && (
                                    <ApplicationStatus
                                      UserData={taxReliefData}
                                    />
                                  )}
                              </Tab.Pane>

                              <Tab.Pane eventKey="tabNew48">
                                {taxReliefData &&
                                  Object.keys(taxReliefData)?.length > 0 && (
                                    <StatusFinalDocument
                                      UserData={taxReliefData}
                                    />
                                  )}
                              </Tab.Pane>

                              <Tab.Pane eventKey="tabNew5">
                                {activeQusSubKey === "subSection1" && (
                                  <Questionnaire
                                    qus={discoveryData}
                                    discoveryCallId={discoveryCallId}
                                  />
                                )}
                                {activeQusSubKey === "subSection2" && (
                                  <Questionnaire
                                    qus={technicalData}
                                    discoveryCallId={discoveryCallId}
                                  />
                                )}
                              </Tab.Pane>

                              <Tab.Pane eventKey="tabNew6">
                                {/* <div>Main Workbook Content</div> */}
                                {activeSubKey === "subSection1" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`Direct Staff 2024-2025`}
                                      col1="Name(Staff)"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      isEditMode={true}
                                      data={directStaffData}
                                      UserData={UserData}
                                      sideBarKey={sideBarKey}
                                      fetchAllData={fetchAllData}
                                      selectedYear={selectedYear}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection2" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`Sub-Contractors 2024-2025`}
                                      col1="Name(subcontractor)"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      isEditMode={true}
                                      data={subcontractsData}
                                      UserData={UserData}
                                      sideBarKey={sideBarKey}
                                      fetchAllData={fetchAllData}
                                      selectedYear={selectedYear}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection3" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`Consumables 2024-2025`}
                                      col1="Name/type of consumable"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      isEditMode={true}
                                      data={consumablesData}
                                      UserData={UserData}
                                      sideBarKey={sideBarKey}
                                      fetchAllData={fetchAllData}
                                      selectedYear={selectedYear}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection4" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`It & Software Costs 2024-2025`}
                                      col1="Name/type of software"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      isEditMode={true}
                                      data={itSoftwareData}
                                      UserData={UserData}
                                      sideBarKey={sideBarKey}
                                      fetchAllData={fetchAllData}
                                      selectedYear={selectedYear}
                                    />
                                  </div>
                                )}
                                {activeSubKey === "subSection5" && (
                                  <div className="sub-content">
                                    <WorkBook
                                      heading={`Other 2024-2025`}
                                      col1="Name"
                                      col2="Projects"
                                      col3="Involvement/Responsibility"
                                      col4="Notes"
                                      col5="% of T spent on R&D Project"
                                      col6="Total Yearly Spent"
                                      isEditMode={true}
                                      data={itSoftwareData}
                                      UserData={UserData}
                                      sideBarKey={sideBarKey}
                                      fetchAllData={fetchAllData}
                                      selectedYear={selectedYear}
                                    />
                                  </div>
                                )}
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
