import React from "react";
import { Dropdown } from "react-bootstrap";
import Profile from "../../assets/images/profile_av.svg";
import { connect } from "react-redux";
import { Onopenmodalsetting } from "../../Redux/Actions/Action";
import { Link } from "react-router-dom";

function Header(props) {
  let user = JSON?.parse(localStorage?.getItem("userData")) || {};

  return (
    <div className="header">
      <nav className="navbar ">
        <div className="container-xxl gap-3">
          <div className="h-right d-flex align-items-center order-1">
            <div className="setting">
              <a
                href="#!"
                onClick={(val) => {
                  props.Onopenmodalsetting(true);
                }}
              >
                <i className="icofont-gear-alt fs-5"></i>
              </a>
            </div>
          </div>
          <button
            className="navbar-toggler p-0 border-0 menu-toggle order-3"
            type="button"
            onClick={() => {
              var sidebar = document.getElementById("mainsidemenu");
              if (sidebar) {
                if (sidebar.classList.contains("open")) {
                  sidebar.classList.remove("open");
                } else {
                  sidebar.classList.add("open");
                }
              }
            }}
          >
            <span className="fa fa-bars"></span>
          </button>
        </div>
      </nav>
    </div>
  );
}

const mapSatetToProps = ({ Mainreducer }) => ({
  Mainreducer,
});

export default connect(mapSatetToProps, {
  Onopenmodalsetting,
})(Header);
