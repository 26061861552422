import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { FaEdit, FaSave } from "react-icons/fa";
import "./index.css"; // Import the custom styles

const BasicInfo = ({ mode, data, onUpdateSuccess }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    company: "",
    companyNumber: "",
    address: "",
  });

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (data) {
      setDefaultValues({
        id: data?.id,
        name: data.full_name || "",
        email: data.email || "",
        phone: data.mobile_no || "",
        company: data.company_name || "",
        companyNumber: data.company_mobile_no || "",
        address: data.address || "",
      });
      setValue("id", data.id || "");
      setValue("name", data.full_name || "");
      setValue("email", data.email || "");
      setValue("phone", data.mobile_no || "");
      setValue("company", data.company_name || "");
      setValue("companyNumber", data.company_mobile_no || "");
      setValue("address", data.address || "");
    }
  }, [data, setValue]);

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const onSubmit = async (formData) => {
    try {
      // Make API request to update data
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}tax-user/${data?.id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email,
            full_name: formData.name,
            mobile_no: formData.phone,
            company_name: formData.company,
            company_mobile_no: formData.companyNumber,
            address: formData.address,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Assuming the response contains the updated data
      const updatedData = await response.json();
      console.log("Update successful:", updatedData);

      // Optionally, trigger a parent callback to handle success
      if (onUpdateSuccess) {
        onUpdateSuccess(updatedData);
      }

      // Optionally, update the local state with new data if needed
      setDefaultValues(updatedData);
      setIsEditing(false);
    } catch (error) {
      console.error("Update failed:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  // Check if data is still loading or not available
  if (!data) {
    return <div>Loading...</div>; // Or handle loading state here
  }

  // Check if the API response is valid
  const isValidData = data && typeof data === "object";

  if (!isValidData) {
    return <div>Error: Invalid data received</div>; // Handle API errors
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="basic-info-container">
      <div className="basic-info-header">
        Personal Information
        {mode === "edit" && (
          <button
            type="button"
            onClick={handleEdit}
            className="btn btn-secondary edit-button "
            style={{ marginLeft: "10px" }}
          >
            {isEditing ? <FaSave /> : <FaEdit />}
          </button>
        )}
      </div>
      <div className="row basic-info-row">
        <div className="col-md-6">
          <div className="basic-info-label">Name:</div>
          <div className="basic-info-value edit-field">
            {isEditing ? (
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control"
                    placeholder="Enter full name"
                  />
                )}
              />
            ) : (
              <span>{watch("name")}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="basic-info-label">Email:</div>
          <div className="basic-info-value edit-field">
            {isEditing ? (
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control"
                    placeholder="Enter email"
                  />
                )}
              />
            ) : (
              <span>{watch("email")}</span>
            )}
          </div>
        </div>
      </div>
      <div className="row basic-info-row">
        <div className="col-md-6">
          <div className="basic-info-label">Phone:</div>
          <div className="basic-info-value edit-field">
            {isEditing ? (
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control"
                    placeholder="Enter phone number"
                  />
                )}
              />
            ) : (
              <span>{watch("phone")}</span>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="basic-info-label">Company:</div>
          <div className="basic-info-value edit-field">
            {isEditing ? (
              <Controller
                name="company"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control"
                    placeholder="Enter company name"
                  />
                )}
              />
            ) : (
              <span>{watch("company")}</span>
            )}
          </div>
        </div>
      </div>
      <div className="row basic-info-row">
        <div className="col-md-6">
          <div className="basic-info-label">Company Mobile:</div>
          <div className="basic-info-value edit-field">
            {isEditing ? (
              <Controller
                name="companyNumber"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control"
                    placeholder="Enter company number"
                  />
                )}
              />
            ) : (
              <span>{watch("companyNumber")}</span>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="basic-info-label">Address:</div>
          <div className="basic-info-value edit-field">
            {isEditing ? (
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control"
                    placeholder="Enter address"
                  />
                )}
              />
            ) : (
              <span>{watch("address")}</span>
            )}
          </div>
        </div>
      </div>
      {isEditing && (
        <div className="update-button-container">
          <button type="submit" className="btn btn-primary update-button">
            <FaSave /> Update
          </button>
        </div>
      )}
    </form>
  );
};

export default BasicInfo;
